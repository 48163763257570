import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import ProgressiveImage from 'react-progressive-image'
import { convertToUSD, trackingGA } from '@merchx-v2/helpers'
import { useSiteMetadata } from 'hooks/use-site-metadata'
import { Header, Footer, FacebookPixels, Notification, SEO } from 'components'
import vipClubLogo from 'assets/vipClubLogo.png'
import 'slick-carousel/slick/slick.scss'
import 'slick-carousel/slick/slick-theme.scss'
import { actionCreators as loginActionCreators, selectors as loginSelectors } from 'state/LoginPage/reducer'
import { actionCreators, selectMenu, selectProducts } from 'state/HomePage/reducer'
import * as styles from './CollectionPage.module.scss'

if (typeof window !== 'undefind' && typeof document !== 'undefined') {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const HomePage = ({ menu, products, loginState, setMenuCollection, setPageAfterLogin, pageContext }) => {
  const [notification, showNotification] = useState()
  const { shared } = useSiteMetadata()

  useEffect(() => {
    trackingGA()
    sessionStorage.setItem('wasPopupShowed', true)
  }, [])

  useEffect(() => {
    setPageAfterLogin('/')
  }, [setPageAfterLogin])

  useEffect(() => {
    showNotification(loginState.UIState.error)
  }, [loginState.UIState.error])

  pageContext?.collectionName && setMenuCollection(pageContext.collectionName);

  return (
    <div className={styles.homePage}>
      <SEO
        title={pageContext?.collectionName || null}
        description={pageContext?.collectionName || null}
        keywords={pageContext?.collectionName || null}
        pathname={`/category/${pageContext?.collectionName}`}
      />
      <div className={styles.preHeader}>
        <img src={vipClubLogo} alt='VIP CLUB' className={styles.vipClubLogo} />
        <div className={styles.prelogoText}>
          Become a VIP member and shop t-shirts for FREE!{' '}
          <Link to='/membership' className={styles.prelogoTextUnderlined}>
            Details
          </Link>
        </div>
      </div>
      <Header isNeedDescription={!!menu?.selectedCollection} gap={true} />
      <FacebookPixels id={shared.FACEBOOK_PIXEL_ID} event='CompleteRegistration' />

      <div className={styles.content}>
        <div className={styles.collectionPageTitle}>{menu?.selectedCollection}</div>

        {menu?.selectedCollection &&
          menu?.items
            .filter(item => item.name === menu.selectedCollection)
            .map(collection => {
              return (
                <div className={styles.galleryWrapper} key={collection.key}>
                  <div className={styles.collectionContainer}>
                    <div className={styles.collectionDescription}>
                      <div className={styles.collectionLenght}>{products.length || 0} Products</div>
                      <div className={styles.collectionNew}>New designs added every day!</div>
                    </div>

                    <div className={styles.selectCollection}>
                      {products?.map(product => {
                        return (
                          <Link
                            className={styles.collectionItem}
                            rel='noopener noreferrer'
                            to={`/products/${(product.hasCustomSeo && product.seoUrl) || product.productId}`}
                            key={product.id}
                          >
                            <div className={styles.productCard}>
                              <ProgressiveImage src={product.previewImageUrl}>{src => <img src={src} alt={product.name} className={styles.productImg} />}</ProgressiveImage>
                              <div className={styles.productInfo}>
                                <div>{product.collectionName}</div>
                              </div>

                              <div className={styles.productName}>{product.productDisplayName}</div>
                              <div className={styles.productName}>${convertToUSD(product.finalPrice)}</div>
                              <div className={styles.productMemberPrice}>${convertToUSD(product.memberPrice)} (member price)</div>
                            </div>
                          </Link>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )
            })}
      </div>

      <Notification message={notification} />
      <div className={styles.footerContainer}>
        <Footer />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  menu: selectMenu(state),
  products: selectProducts(state),
  loginState: loginSelectors.selectLoginState(state)
})

const mapDispatchToProps = {
  resetMenuFilters: actionCreators.resetMenuFilters,
  setMenuCollection: actionCreators.setMenuCollection,
  setMenuSubcollection: actionCreators.setMenuSubcollection,
  setPageAfterLogin: loginActionCreators.setPageAfterLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
