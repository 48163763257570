// extracted by mini-css-extract-plugin
export var homePage = "CollectionPage-module--homePage--3zlZ9";
export var content = "CollectionPage-module--content--2VK4a";
export var preHeader = "CollectionPage-module--preHeader--3yOXn";
export var vipClubLogo = "CollectionPage-module--vipClubLogo--1FAYp";
export var prelogoText = "CollectionPage-module--prelogoText--2zLdb";
export var prelogoTextUnderlined = "CollectionPage-module--prelogoTextUnderlined--2hmvt";
export var searchBanner = "CollectionPage-module--searchBanner--2E1Z4";
export var tooltipBtn = "CollectionPage-module--tooltipBtn--1LFwF";
export var closePopupIcon = "CollectionPage-module--closePopupIcon--1oqGn";
export var newHere = "CollectionPage-module--newHere--1vekR";
export var signUp = "CollectionPage-module--signUp--KXF86";
export var tooltip = "CollectionPage-module--tooltip--2BEG1";
export var tooltipClosed = "CollectionPage-module--tooltipClosed--1Wk-5";
export var blackLogo = "CollectionPage-module--blackLogo--1fYmc";
export var bannerTitle = "CollectionPage-module--bannerTitle--2UxgK";
export var searchContainer = "CollectionPage-module--searchContainer--ISVAX";
export var quickSearch = "CollectionPage-module--quickSearch--2r95x";
export var quickSearchList = "CollectionPage-module--quickSearchList--QxXEv";
export var quickSearchItem = "CollectionPage-module--quickSearchItem--3e_IR";
export var viewAllCategories = "CollectionPage-module--viewAllCategories--1X-o6";
export var sectionWrapper = "CollectionPage-module--sectionWrapper--1tQzl";
export var joinVip = "CollectionPage-module--joinVip--1giu6";
export var joinVipSubtitle = "CollectionPage-module--joinVipSubtitle--1_noJ";
export var joinVipTitle = "CollectionPage-module--joinVipTitle--IYKCB";
export var joinVipText = "CollectionPage-module--joinVipText--1O5GL";
export var form = "CollectionPage-module--form--1xc00";
export var inputContainer = "CollectionPage-module--inputContainer--1GKp_";
export var buttonContainer = "CollectionPage-module--buttonContainer--2gc0M";
export var passwordRules = "CollectionPage-module--passwordRules--3BOu8";
export var alreadyMember = "CollectionPage-module--alreadyMember--218pb";
export var gallery = "CollectionPage-module--gallery--2RkBx";
export var intro = "CollectionPage-module--intro--2WCft";
export var introTitle = "CollectionPage-module--introTitle--1fYZz";
export var introList = "CollectionPage-module--introList--VPDNa";
export var prevSlide = "CollectionPage-module--prevSlide--szMxq";
export var nextSlide = "CollectionPage-module--nextSlide--1oj1l";
export var introItem = "CollectionPage-module--introItem--3AcKj";
export var introItemName = "CollectionPage-module--introItemName--a3mzF";
export var introItemText = "CollectionPage-module--introItemText--bsyXu";
export var introItemBtn = "CollectionPage-module--introItemBtn--3bY5s";
export var about = "CollectionPage-module--about--kpyR1";
export var aboutTitle = "CollectionPage-module--aboutTitle--AIp-B";
export var aboutIcon = "CollectionPage-module--aboutIcon--3piL3";
export var whyContainer = "CollectionPage-module--whyContainer--2JtM6";
export var whyTitle = "CollectionPage-module--whyTitle--1lCiF";
export var whyInfo = "CollectionPage-module--whyInfo--3oBDi";
export var customers = "CollectionPage-module--customers--2dbZL";
export var customersGallery = "CollectionPage-module--customersGallery--oEF08";
export var customersGalleryItem = "CollectionPage-module--customersGalleryItem--5h5IA";
export var subscribeSection = "CollectionPage-module--subscribeSection--2iYx7";
export var subscribeTitle = "CollectionPage-module--subscribeTitle--3fACR";
export var subscribeText = "CollectionPage-module--subscribeText--3uLZz";
export var socialBox = "CollectionPage-module--socialBox--2VSxC";
export var socialItem = "CollectionPage-module--socialItem--11JPh";
export var socialIcon = "CollectionPage-module--socialIcon--3PjAk";
export var button = "CollectionPage-module--button--3Jeqd";
export var collectionPageTitle = "CollectionPage-module--collectionPageTitle--3Z5Bz";
export var galleryWrapper = "CollectionPage-module--galleryWrapper--1WLfS";
export var collectionContainer = "CollectionPage-module--collectionContainer--3myGi";
export var collectionDescription = "CollectionPage-module--collectionDescription--3jm3K";
export var productCard = "CollectionPage-module--productCard--1MjZ-";
export var productImg = "CollectionPage-module--productImg--3L3tW";
export var productInfo = "CollectionPage-module--productInfo--12sHX";
export var productName = "CollectionPage-module--productName--1S96N";
export var productMemberPrice = "CollectionPage-module--productMemberPrice--lsCD9";
export var collectionLenght = "CollectionPage-module--collectionLenght--1JUvA";
export var collectionNew = "CollectionPage-module--collectionNew--1zZA6";
export var selectCollection = "CollectionPage-module--selectCollection--1DTvy";
export var collectionItem = "CollectionPage-module--collectionItem--3U8Sa";
export var footerContainer = "CollectionPage-module--footerContainer--2FYiR";